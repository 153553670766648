import {Toast} from "vant";
import api from "../../service/api";

export default {
  state:{
    user:{},
    fromCompany:{
      groupId:150
    },
    location:{
      province:'江苏省',
      city:'南京市',
      district:'',
      longitude:118.796623,
      latitude:32.059352,
      areaCode:'3201'
    }
  },
  mutations:{
    CURRENT_USER:(state, data) => {
      state.user = data;
    },
    CURRENT_CITY:(state, location) => {
      state.location = location;
    },
    FROM_COMPANY:(state, company)=>{
      state.fromCompany = company;
    }
  },
  actions:{
    loadFromCompanyInfo(context, code){
      if(code && code != ''){
        if(code.length > 4){
          code = code.substring(0,4);
        }
        return api.get(encodeURI(`/group/PICC|${code}`)).then(resp => {
          if(resp.success){
            if(resp.data === null){
              Toast.fail("获取订单来源信息失败")
              return;
            }
            context.commit('FROM_COMPANY', resp.data);
          } else {
            Toast.fail("获取订单来源信息失败")
          }
        })
      }
    }
  }
}

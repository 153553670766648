import Vue from "vue";
import VueRouter from "vue-router";
import Inspection from "../views/Inspection";
import api from "../service/api";
import {isWeChatBrowser, isIos} from "../utils/weChatUtil";
import securityInspectBook from "../views/securityInspectBook";
import driverInspection from "../views/driver/driverInspection";
import driverBook from "../views/driver/driverBook";

Vue.use(VueRouter);

const routes = [
  {
    path:'/group/order/book',
    component:Inspection,
  },
  {
    path:'/group/driver/index',
    component:driverInspection,
  },{
    path:'/group/driver/book',
    component:driverBook,
  },{
    path:'/group/security/inspection',
    component:()=>import('../views/securityInspectIndex'),
  },{
    path:'/group/security/inspection/book',
    component:securityInspectBook,
  },{
    path:'/nearby',
    component:()=>import('../views/nearby'),
  },{
    path:'/store/:storeId',
    component:()=>import('../views/storeDetail'),
  },{
    path:'/inspection/book/:storeId',
    component:()=>import('../views/book'),
  },{
    path:'/orders',
    component:()=>import('../views/order/list'),
  }, {
    path:'/order/:orderNo',
    component:()=>import('../views/order/detail'),
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from,next) =>{

  if(isWeChatBrowser()){

    let url = location.href.split('#')[0]
    if(isIos()){
      url =  window.location.href;
    }

    api.post("/wechat/jsapi", url,{headers: {'Content-Type': 'text/plain'}})
      .then(resp => {
        if(resp.success){
          const sign = resp.data;
          router.app.$wechat.config({
            debug: false,
            appId: sign.appId,
            timestamp: sign.timestamp,
            nonceStr: sign.nonceStr,
            signature: sign.signature,
            jsApiList: [
              'getLocation', 'openLocation', 'onMenuShareTimeline',
              'onMenuShareAppMessage', 'chooseWXPay', 'showMenuItems', 'hideMenuItems',
              'updateAppMessageShareData', 'updateTimelineShareData','hideAllNonBaseMenuItem'
            ]
          });
        }
      });
  }
  next();
});







export default router;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex";
import "./registerServiceWorker";
import './assets/less/base.less';
import axios from '@/service/api';
import FreedomIcon from "./components/Icon/FreedomIcon";
import wx from 'weixin-js-sdk';

Vue.prototype.$wechat = wx;
Vue.wechat = wx;

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.component('FreedomIcon', FreedomIcon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

/**
 * 两个地点之间计算距离
 * @param currentLocation 当前位置
 * @param targetLocation 目的地
 * @returns {string}
 */
export function computeDistance(currentLocation, targetLocation){
  let radLat1 = currentLocation.latitude * Math.PI / 180.0;
  let radLat2 = targetLocation.latitude * Math.PI / 180.0;
  let a = radLat1 - radLat2;
  let  b = currentLocation.longitude * Math.PI / 180.0 - targetLocation.longitude * Math.PI / 180.0;
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
    Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
  s = s *6378.137 ;// EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000; //输出为公里
  s=s.toFixed(2);
  return s;
}

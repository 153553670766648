<template>
  <div class="page">
    <van-nav-bar title="预约上门接车"   left-text="返回" left-arrow @click-left="()=>{this.$router.go(-1)}"></van-nav-bar>
    <van-cell-group>
      <van-field label="车牌号" :maxLength="8" required v-model="customerPlateNum" :error-message="customerPlateNumError" placeholder="请输入车牌号"  />
      <van-field label="姓名" required v-model="customerName" :error-message="customerNameError" placeholder="请填写车主姓名"  />
      <van-field label="手机号" type="tel"
                 :maxLength="11" required
                 v-model="customerMobile"
                 :error-message="customerMobileError"
                 placeholder="请填写手机号"  />



      <van-field label="省市区" required :value="`${province}${city}${district}`"
                 :error-message="provinceError"
                 placeholder="请选择所在省市区"
                 is-link
                 readonly
                 @click="()=>{this.areaShow=true}"
      ></van-field>
      <van-field label="接车地址" required v-model="hourseAddress"
                 :error-message="hourseAddressError"
                 placeholder="请输入详细接车地址"
      ></van-field>

      <van-field label="预约日期"
                 is-link
                 required :value="planDate"
                 :error-message="planDateError"
                 placeholder="请选择预约日期" readonly
                 @click="planDateShow = true"/>
      <van-field label="预约时间" required :value="planTime"
                 :error-message="planTimeError"
                 placeholder="请选择预约时间"
                 readonly
                 is-link
                 @click="planTimeShow = true"/>
    </van-cell-group>

    <van-button class="inspection-book-btn" type="primary"
                color="#fb6646"
                :loading="submitLoading"
                @click="submit"
    >提交订单</van-button>


    <van-cell-group  title="提示">
      <ul class="inspection-book-tips">
        <li>所有输入数据将传输给江苏旭日阳光云车网络科技有限公司，仅用于本次服务。</li>
        <li>在法律许可范围内，人保财险江苏省分公司保留对此服务的解释权。</li>
      </ul>
    </van-cell-group>


    <van-calendar v-model="planDateShow" color="#fb6646" :min-date="minDate" @confirm="planDateConfirm" />
    <van-popup v-model="planTimeShow"
               position="bottom">
      <van-datetime-picker
              class="inspect__plan-time"
              type="time"
              title="选择时间"
              :filter="timeFilter"
              :formatter="formatter"
              @confirm="planTimeConfirm"
              @cancel="()=>{planTimeShow=false}"
      />
    </van-popup>



    <van-popup v-model="areaShow"   position="bottom">
      <van-area title="请选择省市区" :area-list="areaList" @cancel="()=>{this.areaShow =false}"  @confirm="areaConfirm"/>
    </van-popup>

  </div>
</template>

<script>

  import {
    CellGroup,
    Field,
    NavBar,
    Popup,
    Area,
    Calendar,
    DatetimePicker,
    Button,
    Toast, Notify
  } from 'vant';
  import dayjs from "dayjs";
  import areaList from "../../config/areaList";
  import {mapState} from 'vuex';
  import {checkChineseName, checkMobile, checkVehicleNumber} from "../../utils/validateUtils";
  export default {
    name: "securityInspectBook",
    components:{
      vanCellGroup:CellGroup,
      vanField:Field,
      vanNavBar:NavBar,
      vanPopup:Popup,
      vanCalendar:Calendar,
      vanDatetimePicker:DatetimePicker,
      vanButton:Button,
      vanArea:Area,
    },
    data(){
      return {
        areaShow:false,
        areaList,
        customerPlateNum: '',
        customerPlateNumError:'',
        customerName:'',
        customerNameError:'',
        customerMobile:'',
        customerMobileError:'',
        planDate:'',
        planDateError:'',
        planDateShow:false,
        planTime:'',
        planTimeError:'',
        planTimeShow:false,
        hourseAddress:'',
        hourseAddressError:'',
        province:"",
        provinceError:'',
        city:"",
        district:"",
        submitLoading:false,
      }
    },
    computed:{
      ...mapState({
        location:state => state.app.location
      }),
      minDate(){
        const now = new Date();
        return new Date(now.getFullYear(),now.getMonth(), now.getDate() + 1);
      }
    },
    methods:{
      areaConfirm(value){
        this.province = value[0].name;
        this.city = value[1].name;
        this.district = value[2].name;
        this.areaShow = false;
      },

      timeFilter(type, options){
        if (type === 'minute') {
          return options.filter((option) => option % 15 === 0);
        }
        if (type === 'hour') {
          return options.filter((option) => option >= 9 && option <= 16);
        }
      },
      formatter(type, val) {
        if (type === 'hour') {
          new Date().getMinutes();
          return val + '时';
        }
        if (type === 'minute') {
          return val + '分';
        }
        return val;
      },
      planDateConfirm(date){
        this.planDate = dayjs(date).format("YYYY-MM-DD");
        this.planDateShow = false;
      },
      planTimeConfirm(value){
        this.planTime = value;
        this.planTimeShow = false;
      },
      checkForm(){

        if(!checkVehicleNumber(this.customerPlateNum)){
          this.customerPlateNumError = "请输入正确的车牌";
          return false;
        } else {
          this.customerPlateNumError = "";
        }

        if(!checkChineseName(this.customerName)){
          this.customerNameError = "请输入正确的中文姓名";
          return false;
        } else {
          this.customerNameError = "";
        }
        if(!checkMobile(this.customerMobile)){
          this.customerMobileError = "请输入正确的手机号";
          return false;
        } else {
          this.customerMobileError = "";
        }

        if(this.province == ''){
          this.provinceError = "请选择省市区";
          return false;
        } else {
          this.provinceError = "";
        }
        if(this.planDate == ""){
          this.planDateError = "请选择预约日期";
          return false;
        } else {
          this.planDateError = "";
        }
        if(this.planTime == null || this.planTime == ""){
          this.planTimeError = '请选择预约时间';
          return false;
        } else {
          this.planTimeError = '';
        }
        if(this.hourseAddress == ""){
          this.hourseAddressError = '请输入详细接车地址';
          return false;
        } else {
          this.hourseAddressError = '';
        }
        return true;

      },
      submitData(){
        const _this = this;
        const {planDate, planTime,province,city,district,hourseAddress} = this;
        const param = {
          customerName:this.customerName,
          customerMobile:this.customerMobile,
          customerPlateNum:this.customerPlateNum,
          houseAddress:`${province}${city}${district}${hourseAddress}`,
          planTime:`${planDate} ${planTime}`,
          orderType:18,
          fromGroupId:417,
          sourceType:4,
          providerId:'PICC',
          extOrderNo:sessionStorage.getItem('svcCode'),
          serviceContent:'上门接车年检'
        };
        this.submitLoading = true;
        this.$http.post("/picc/jiangsu/order",param)
          .then(resp => {
            if(resp.success){
              Toast({
                message:'提交成功',
                onClose(){
                  _this.$router.push("/orders")
                }
              })
            } else {
              Notify(resp.message);
            }
          }).finally(()=>{
          this.submitLoading = false;
        })
      },
      submit(){
        if(!this.checkForm()){
          return;
        }
        const svcCode = sessionStorage.getItem("svcCode");
        this.$http.post("/picc/jiangsu/serviceOrder",{
          svcCode,
          subServiceType:"3",
          longitude:this.location.longitude,
          latitude:this.location.latitude,
          address:this.location.formatAddress,
          areaCode:this.location.areaCode,
        }).then(resp => {
          if(resp.success){
            this.submitData();
          } else {
            Notify("验券下单-"+resp.message);
          }
        })

      }
    },
    created() {
      this.province = this.location.province;
      this.city = this.location.city;
      this.district = this.location.district;
    }
  }
</script>

<style lang="less" scoped>
  @import "../../assets/less/variable";
  @import "../../assets/less/utils";


  .inspection-book-btn{
    margin:20px auto 10px;
    width: 90%;
  }


  .storeList{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .store{
    border-bottom: 1px solid  @gray-3;
    display: flex;
    flex-direction: column;
    padding:10px 16px;
    .title{
      font-size: 16px;
      font-weight: bold;
    }
    .location-wrap{
      display: flex;
      margin-top: 8px;
      justify-content: space-between;
    }
    .address,.kilometer{
      .textOverflow();
      font-size: 14px;
      color:@gray-6;
    }
    .address{
      width: 70%;
    }
  }

  .tags{
    margin-top: 6px;
    display: flex;
    .tag{
      background: rgba(255,151,106,0.4);
      color:@orange-dark;
      padding:2px;
      font-size: 12px;
    }
  }

  .inspection-book-tips{
    padding:15px;
    li{
      list-style-type: decimal;
      margin-left:15px;
      font-size: @font-size-md;
      line-height: 20px;
    }
  }
  .selected-inspect-item{
    display: flex;
    flex-direction: column;
    color: #969799;
    justify-content: left;
    span{
      text-align: left;
    }
  }


</style>

import axios from 'axios'
import {API_DOMAIN, TOKEN_KEY} from '@/config';
import {Notify} from "vant";

axios.defaults.baseURL = API_DOMAIN;
axios.defaults.headers['Content-Type']='application/json;charset=UTF-8';

axios.interceptors.request.use(config => {

    if(config.url.indexOf("restapi.amap.com") > 0){
      return config;
    }

    if (config.url === '/login') {
      localStorage.removeItem(TOKEN_KEY);
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (token != null) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  // 处理响应数据
  return response.data;
},  (error) =>  {
  console.error(error);
  if (error.response) {
    switch (error.response.status) {
      case 401:
      case 403:
        //跳转到登录界面
        localStorage.removeItem(TOKEN_KEY);
        Notify("授权已过期")
    }
  } else {
    Notify("网络错误，服务器未响应");
  }
  //处理响应失败
  return Promise.reject(error);
});

export default {
  get(url, params={}) {
    return axios.get(url, params);
  },
  post(url, params={}, config={}) {
    return axios.post(url, params, config);
  },
  delete(url, params={}) {
    return axios.delete(url, params);
  },
  put(url, params={}, config={}) {
    return axios.put(url, params, config);
  }

};

<template>
    <svg class="freedom-icon" aria-hidden="true">
        <use :xlink:href="iconName"></use>
    </svg>
</template>

<script>
  export default {
    name: "FreedomIcon",
    props: {
      iconClass: {
        type: String,
        required: true
      }
    },
    computed: {
      iconName() {
        return `#${this.iconClass}`
      }
    }
  }
</script>

<style>
    .freedom-icon{
        width: 1em; height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
    svg{
      fill:currentColor;
    }
</style>

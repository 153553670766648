import {AUTHOR_URL, WE_CHAT_APP_ID} from '../config/index';

const AUTH_BASE = "snsapi_base";

//const AUTH_USER_INFO = "snsapi_userinfo";

export function redirectAuth() {
    let url = AUTHOR_URL.replace("APPID", WE_CHAT_APP_ID)
        .replace("REDIRECT_URI", encodeURIComponent(window.location.href))
        .replace("SCOPE", AUTH_BASE)
        .replace("STATE", new Date().getTime());
    return url;
}

export function isIos() {
    const u = navigator.userAgent

    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
}

export function isAndroid() {
    const u = navigator.userAgent
    return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
}

export function isWeChatBrowser() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger' //android终端或者uc浏览器
}



